import React from 'react';
import { useHistory } from 'react-router-dom';

export default function UserLink(props) {
  const user = props.user;
  const link = `/users/${user.id}`;
  const history = useHistory();

  const openProfile = (e) => {
    e.preventDefault();

    history.push(link);
  };

  return (
    <a
      href={link}
      onClick={openProfile}
      target="_blank"
      rel="noopener noreferrer"
    >
      {props.children}
    </a>
  );
}
