import { isShiftSupervisor } from '../utils';

export const permissions = [
  // isRestrictedComponent
  { id: 1, code: 'CREATE_API_BUSINESS' },
  { id: 2, code: 'VIEW_API_BUSINESS_INSIGHTS' },
  { id: 3, code: 'VIEW_API_BUSINESS_TRANSACTIONS' },
  { id: 4, code: 'VIEW_API_BUSINESS_CLIENTS' },
  { id: 5, code: 'VIEW_API_BUSINESS_WALLET' },
  { id: 6, code: 'VIEW_API_BUSINESS_COMMISSIONS' },
  { id: 7, code: 'VIEW_API_BUSINESS_SETTLEMENTS' },
  { id: 8, code: 'VIEW_SERVICES' },
  { id: 9, code: 'VIEW_SERVICE_PROVIDER_INSIGHTS' },
  { id: 10, code: 'VIEW_SERVICE_PROVIDER_TRANSACTIONS' },
  { id: 11, code: 'VIEW_SERVICE_PROVIDER_INCIDENTS' },
  { id: 12, code: 'VIEW_SERVICE_PROVIDER_SETTINGS' },
  { id: 13, code: 'VIEW_SERVICE_PROVIDER_SHIFT_ENQUIRIES' },
  { id: 14, code: 'VIEW_SERVICE_PROVIDER_CUSTOMER_ENQUIRIES' },
  { id: 15, code: 'VIEW_GATEWAY_DETAILS' },
  { id: 16, code: 'VIEW_GATEWAY_TRANSACTIONS' },
  { id: 17, code: 'VIEW_ADMIN_DASHBOARD' },

  // isFinanceComponent
  { id: 18, code: 'VIEW_PAYMENT_GATEWAY_DETAILS' },
  { id: 19, code: 'VIEW_PAYMENT_GATEWAY_SETTLEMENTS' },
  { id: 20, code: 'UPDATE_MERCHANT_WALLET' },
  { id: 21, code: 'VIEW_AGENT_SETTLEMENTS' },
  { id: 22, code: 'VIEW_AGENT_CANCELLATIONS' },

  // isSupportComponent
  { id: 23, code: 'VIEW_SUPPORT_SUPERVISOR' },
  { id: 24, code: 'UPDATE_SUPPORT_SUPERVISOR' },

  // isRestrictedSupportComponent
  { id: 25, code: 'VIEW_APP_CONFIG' },
  { id: 26, code: 'UPDATE_APP_CONFIG' },

  // isMerchantSupportComponent
  { id: 27, code: 'VIEW_MERCHANTS_DEFAULT_COMMISSIONS' },
  { id: 28, code: 'UPDATE_MERCHANTS_DEFAULT_COMMISSIONS' },
  { id: 29, code: 'VIEW_MERCHANT_DETAILS' },
  { id: 30, code: 'VIEW_MERCHANT_WALLET' },
  { id: 31, code: 'VIEW_MERCHANT_ORDERS' },
  { id: 32, code: 'VIEW_MERCHANT_OVERRIDE_COMMISSIONS' },
  { id: 33, code: 'UPDATE_MERCHANT_OVERRIDE_COMMISSIONS' },
  { id: 34, code: 'VIEW_MERCHANTS' },
];

export const permission = {
  // isRestrictedComponent
  CREATE_API_BUSINESS: Symbol.for('CREATE_API_BUSINESS'),
  VIEW_API_BUSINESS_INSIGHTS: Symbol.for('VIEW_API_BUSINESS_INSIGHTS'),
  VIEW_API_BUSINESS_TRANSACTIONS: Symbol.for('VIEW_API_BUSINESS_TRANSACTIONS'),
  VIEW_API_BUSINESS_CLIENTS: Symbol.for('VIEW_API_BUSINESS_CLIENTS'),
  VIEW_API_BUSINESS_WALLET: Symbol.for('VIEW_API_BUSINESS_WALLET'),
  VIEW_API_BUSINESS_COMMISSIONS: Symbol.for('VIEW_API_BUSINESS_COMMISSIONS'),
  VIEW_API_BUSINESS_SETTLEMENTS: Symbol.for('VIEW_API_BUSINESS_SETTLEMENTS'),
  VIEW_SERVICES: Symbol.for('VIEW_SERVICES'),
  VIEW_SERVICE_PROVIDER_INSIGHTS: Symbol.for('VIEW_SERVICE_PROVIDER_INSIGHTS'),
  VIEW_SERVICE_PROVIDER_TRANSACTIONS: Symbol.for(
    'VIEW_SERVICE_PROVIDER_TRANSACTIONS'
  ),
  VIEW_SERVICE_PROVIDER_INCIDENTS: Symbol.for(
    'VIEW_SERVICE_PROVIDER_INCIDENTS'
  ),
  VIEW_SERVICE_PROVIDER_SETTINGS: Symbol.for('VIEW_SERVICE_PROVIDER_SETTINGS'),
  VIEW_SERVICE_PROVIDER_SHIFT_ENQUIRIES: Symbol.for(
    'VIEW_SERVICE_PROVIDER_SHIFT_ENQUIRIES'
  ),
  VIEW_SERVICE_PROVIDER_CUSTOMER_ENQUIRIES: Symbol.for(
    'VIEW_SERVICE_PROVIDER_CUSTOMER_ENQUIRIES'
  ),
  VIEW_GATEWAY_DETAILS: Symbol.for('VIEW_GATEWAY_DETAILS'),
  VIEW_GATEWAY_TRANSACTIONS: Symbol.for('VIEW_GATEWAY_TRANSACTIONS'),
  VIEW_ADMIN_DASHBOARD: Symbol.for('VIEW_ADMIN_DASHBOARD'),

  // isFinanceComponent
  VIEW_PAYMENT_GATEWAY_DETAILS: Symbol.for('VIEW_PAYMENT_GATEWAY_DETAILS'),
  VIEW_PAYMENT_GATEWAY_SETTLEMENTS: Symbol.for(
    'VIEW_PAYMENT_GATEWAY_SETTLEMENTS'
  ),
  UPDATE_MERCHANT_WALLET: Symbol.for('UPDATE_MERCHANT_WALLET'),
  VIEW_AGENT_SETTLEMENTS: Symbol.for('VIEW_AGENT_SETTLEMENTS'),
  VIEW_AGENT_CANCELLATIONS: Symbol.for('VIEW_AGENT_CANCELLATIONS'),

  // isSupportComponent
  VIEW_SUPPORT_SUPERVISOR: Symbol.for('VIEW_SUPPORT_SUPERVISOR'),
  UPDATE_SUPPORT_SUPERVISOR: Symbol.for('UPDATE_SUPPORT_SUPERVISOR'),

  // isRestrictedSupportComponent
  VIEW_APP_CONFIG: Symbol.for('VIEW_APP_CONFIG'),
  UPDATE_APP_CONFIG: Symbol.for('UPDATE_APP_CONFIG'),
  VIEW_MERCHANTS: Symbol.for('VIEW_MERCHANTS'),
  VIEW_MERCHANTS_DEFAULT_COMMISSIONS: Symbol.for(
    'VIEW_MERCHANTS_DEFAULT_COMMISSIONS'
  ),
  UPDATE_MERCHANTS_DEFAULT_COMMISSIONS: Symbol.for(
    'UPDATE_MERCHANTS_DEFAULT_COMMISSIONS'
  ),
  VIEW_MERCHANT_DETAILS: Symbol.for('VIEW_MERCHANT_DETAILS'),
  VIEW_MERCHANT_WALLET: Symbol.for('VIEW_MERCHANT_WALLET'),
  VIEW_MERCHANT_ORDERS: Symbol.for('VIEW_MERCHANT_ORDERS'),
  VIEW_MERCHANT_OVERRIDE_COMMISSIONS: Symbol.for(
    'VIEW_MERCHANT_OVERRIDE_COMMISSIONS'
  ),
  UPDATE_MERCHANT_OVERRIDE_COMMISSIONS: Symbol.for(
    'UPDATE_MERCHANT_OVERRIDE_COMMISSIONS'
  ),
};

export const dynamicPermission = {
  IS_SHIFT_SUPERVISOR: Symbol.for('IS_SHIFT_SUPERVISOR'),
};

export const dynamicPermissionHandler = {
  [dynamicPermission.IS_SHIFT_SUPERVISOR]: (auth) => isShiftSupervisor(auth),
};
