import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Table } from 'reactstrap';

import { callApi, formatDateTime } from '../../utils';

import Loading from '../Loading';

function FraudEvents(props) {
  const { user } = props;

  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    callApi(`/users/${user.id}/fraud-events`)
      .then(({ data = [] }) => {
        if (!data || !data.length) {
          return [];
        }

        setRows(data);
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Table responsive={true} striped={true}>
            <thead>
              <tr>
                <th>Event</th>
                <th>Notes</th>
                <th>Status</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((fraudEvent) => (
                <tr key={fraudEvent.id}>
                  <td>{fraudEvent.type}</td>
                  <td>{fraudEvent.notes}</td>
                  <td>{fraudEvent.status}</td>
                  <td>{formatDateTime(fraudEvent.created_at)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default connect()(FraudEvents);
