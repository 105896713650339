import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { callApi, GATEWAYS } from '../../../utils';
import { showError } from '../../../actions/notificationActions';

const defaultProviderProps = {
  formSchema: [],
  config: {
    pageable: {},
  },
};

const providerProps = {
  EKO: {
    ONESAIT: {
      formSchema: [
        {
          type: 'date',
          required: true,
          label: 'Select Payment Date:',
          name: 'paymentDate',
        },
      ],
      config: {
        pageable: {
          paged: true,
          limit: 30,
          total: 0, // field will be hydrated by the server's response
          pages: 0, // field will be hydrated by the server's response
          page: 1, // field is controlled from the client's side
        },
      },
    },
  },
  ABUJA: {
    INCMS: {
      formSchema: [
        {
          type: 'date',
          required: true,
          label: 'Select Payment Date:',
          name: 'paymentDate',
        },
      ],
      config: {
        pageable: {
          paged: false,
        },
      },
    },
  },
};

function ShiftEnquries({ serviceCode = '', dispatch }) {
  const [formData, setFormData] = useState({});
  const [selectedGateway, setSelectedGateway] = useState('');
  const [, setConfig] = useState(defaultProviderProps.config);
  const [reportMetadata, setReportMetadata] = useState(null);
  const [isExporting, setIsExporting] = useState(false);

  useEffect(() => {
    if (selectedGateway) {
      const providerGatewayProps = providerProps[serviceCode][selectedGateway];
      setConfig(
        providerGatewayProps
          ? providerGatewayProps['config']
          : defaultProviderProps['config']
      );
    }
  }, [selectedGateway]);

  function handleInputChange(evt) {
    const { name, value } = evt.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  function handleGatewaySelect(evt) {
    setSelectedGateway(evt.target.value);
  }

  async function handleExportClick() {
    setIsExporting(true);

    if (reportMetadata) {
      downloadFileFromLink(
        reportMetadata.download_url,
        reportMetadata.filename
      );

      return setIsExporting(true);
    }

    callApi(`provider/${serviceCode}/transactions/metadata`, {
      gateway: selectedGateway,
      ...formData,
    })
      .then((result) => {
        const data = result.data;
        setReportMetadata(data);

        downloadFileFromLink(data.download_url, data.filename);
      })
      .catch((err) => {
        dispatch(
          showError(
            'report cannot be downloaded at this time, please try again later',
            err.message
          )
        );
      })
      .finally(() => {
        setIsExporting(false);
      });
  }

  function downloadFileFromLink(link, fileName = 'export.csv') {
    const anchorElement = document.createElement('a');
    anchorElement.setAttribute('href', link);
    anchorElement.setAttribute('download', true);
    anchorElement.setAttribute('filename', fileName);
    document.body.appendChild(anchorElement);
    anchorElement.click();
    return anchorElement.remove();
  }

  const supportedGateways = [
    { value: '', name: 'Please select a Gateway' },
    ...(GATEWAYS[serviceCode] || []),
  ];

  let schema = [];

  if (selectedGateway) {
    const providerGatewayProps = providerProps[serviceCode][selectedGateway];
    schema = providerGatewayProps
      ? providerGatewayProps['formSchema']
      : defaultProviderProps['formSchema'];
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <div>
          <div>
            <label>Select Gateway: </label>
            <select onChange={handleGatewaySelect}>
              {supportedGateways.map((gateway, index) => {
                return (
                  <option key={index} value={gateway.value}>
                    {gateway.name}
                  </option>
                );
              })}
            </select>
          </div>

          <div style={{ display: 'flex', alignContent: 'center' }}>
            {selectedGateway ? (
              schema.map((schem, index) => {
                return (
                  <label key={index}>
                    {schem.label}
                    <input
                      name={schem.name}
                      value={formData[schem.name] || ''}
                      onChange={handleInputChange}
                      required={schem.required}
                      type={schem.type}
                    />
                  </label>
                );
              })
            ) : (
              <div style={{ marginBlockEnd: '10px', fontSize: '19px' }}>
                To continue, please select a gateway
              </div>
            )}
          </div>
        </div>

        <div></div>
      </div>

      <div>
        <Button
          color="success"
          disabled={
            isExporting || !selectedGateway || !Object.keys(formData).length
          }
          onClick={handleExportClick}
        >
          Export to CSV
          {isExporting ? (
            <span
              className="fas fa-spinner fa-spin"
              style={{ marginInlineStart: '5px' }}
            ></span>
          ) : (
            ''
          )}
        </Button>
      </div>
    </div>
  );
}

export default connect()(ShiftEnquries);
