import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './FraudEvents/List';

function FraudEvents(prop) {
  // if (prop.isRestrictedComponent && !_checkACL('promotions')) {
  //   return;
  // }
  return (
    // <div>
    <Switch>
      <Route exact path="/fraud-events" component={List} />
    </Switch>
    // </div>
  );
}

export default FraudEvents;
