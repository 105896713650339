export default [
  // Super Admins
  { user_id: 7, role_id: 1 }, // Abiola Shedrack or Benjamin Ojima
  { user_id: 21, role_id: 1 }, // Dipo Asehinde
  { user_id: 28843, role_id: 1 }, // Tunde Familuisi
  { user_id: 1911392, role_id: 1 }, // Temple Olowonigba
  { user_id: 83261, role_id: 1 }, // Victoria Okafor
  { user_id: 419005, role_id: 1 }, // Olamide Omotunde
  { user_id: 238131, role_id: 1 }, // Joseph Anagwonye
  { user_id: 2074267, role_id: 1 }, // Holy Vuaye
  { user_id: 3601221, role_id: 1 }, // James Ameh

  // Finance
  { user_id: 376958, role_id: 3 }, // Daniel Karanja
  { user_id: 280842, role_id: 3 }, // Tosin Agunbiade
  { user_id: 1466778, role_id: 3 }, // Temidayo Olaobaju
  { user_id: 1629546, role_id: 3 }, // Emmanuel Bamidele
  { user_id: 4172743, role_id: 3 }, // Hussein Moshood
  { user_id: 2316537, role_id: 1 }, // Rahliat Musa
  { user_id: 2527047, role_id: 1 }, // Barnabas Mensah
  { user_id: 6031793, role_id: 3 }, // Precious Okorodas

  // Customer Support
  { user_id: 286, role_id: 4 }, // Saratu Datti

  // Tech Support
  { user_id: 419005, role_id: 5 }, // Olamide Omotunde
  { user_id: 238131, role_id: 5 }, // Joseph Anagwonye
  { user_id: 2074267, role_id: 5 }, // Holy Vuaye
  { user_id: 3601221, role_id: 5 }, // James Ameh

  // Engineering
  { user_id: 28843, role_id: 2 }, // Tunde Familuisi
  { user_id: 1911392, role_id: 2 }, // Temple Olowonigba

  // Partnerships
  { user_id: 83261, role_id: 6 }, // Victoria Okafor
  { user_id: 1133630, role_id: 6 }, // Chisimdi Ene
  { user_id: 6459466, role_id: 6 }, // Samson Dipeolu
  { user_id: 824364, role_id: 6 }, // Peace Enoh
  { user_id: 4755482, role_id: 6 }, // Daniel Kalu
  { user_id: 5455033, role_id: 6 }, // Blessing Taiwo
];
