import React from 'react';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Header, Footer, Sidebar } from '../components';
import routes from '../routes';
import Modals from '../components/Modals';
import {
  _checkACL,
  getRoutes,
  logoutAdmin,
  hasACL,
  fetchACL,
  getSupervisor,
} from '../utils';
import { AuthContext } from '../contexts';
import { dynamicPermissionHandler, role } from '../authorities';

let ps;

class Main extends React.Component {
  state = {
    loading: true,
  };

  mainPanelRef = React.createRef();

  componentDidMount() {
    let finalState = {};

    Promise.all([this.getConfig(), getSupervisor()])
      .then(([config]) => {
        if (!hasACL()) {
          logoutAdmin();
        }

        finalState = {
          ...finalState,
          ...config,
          loading: false,
        };
      })
      .finally(() => {
        this.setState(finalState);
      });
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
      document.body.classList.toggle('perfect-scrollbar-on');
    }
  }

  componentDidUpdate(e) {
    if (!this.state.loading) {
      if (navigator.platform.indexOf('Win') > -1) {
        ps = new PerfectScrollbar(this.mainPanelRef.current);
        document.body.classList.toggle('perfect-scrollbar-on');
      }
      if (e.history.action === 'PUSH') {
        this.mainPanelRef.current.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
      }
    }
  }

  getConfig = async () => {
    await fetchACL();
  };

  render() {
    const { loading } = this.state;

    if (loading) {
      return <div>Loading...</div>;
    }

    return (
      <AuthContext.Consumer>
        {(auth) => {
          return (
            <div className="wrapper">
              <Modals />
              <Sidebar {...this.props} routes={getRoutes(routes)} />
              <div className="main-panel" ref={this.mainPanelRef}>
                <Header {...this.props} />
                <Switch>
                  {routes.map((prop, key) => {
                    if (prop.views) {
                      prop.views.map((prop2, key2) => {
                        return (
                          <Route
                            path={prop2.path}
                            component={prop2.component}
                            key={key2}
                          />
                        );
                      });
                    }

                    if (prop.redirect) {
                      return (
                        <Redirect from={prop.path} to={prop.pathTo} key={key} />
                      );
                    }

                    const {
                      checkACL,
                      path,
                      component,
                      resource,
                      roles = null,
                      permissions = null,
                      dynamicPermissions = null,
                    } = prop;

                    const {
                      roles: userRoles = [],
                      permissions: userPermissions = [],
                    } = auth;

                    const isProtectedRoute = Boolean(
                      roles || permissions || dynamicPermissions
                    );

                    if (isProtectedRoute) {
                      const isSuperAdmin = userRoles.some(
                        (userRole) => userRole === role.SUPER_ADMIN.description
                      );

                      // Check if user has any of the required roles
                      const hasRole =
                        roles &&
                        roles.some((role) =>
                          userRoles.includes(role.description)
                        );

                      // Check if user has any of the required static permissions
                      const hasPermission =
                        permissions &&
                        permissions.some((permission) =>
                          userPermissions.includes(permission.description)
                        );

                      // Check if user has any of the required dynamic permissions
                      const hasDynamicPermission =
                        dynamicPermissions &&
                        dynamicPermissions.some((permission) =>
                          dynamicPermissionHandler[permission](auth)
                        );

                      // If user doesn't have required role or permission [static or dynamic], return null
                      if (
                        !(
                          (checkACL && _checkACL(resource)) ||
                          (permissions && hasPermission) ||
                          (dynamicPermissions && hasDynamicPermission) ||
                          (roles && hasRole) ||
                          isSuperAdmin
                        )
                      ) {
                        return null;
                      }
                    }

                    return (
                      <Route path={path} component={component} key={key} />
                    );
                  })}
                </Switch>
                <Footer fluid />
              </div>
            </div>
          );
        }}
      </AuthContext.Consumer>
    );
  }
}

export default Main;
